<template>
  <div style="text-align: center; text-shadow: 1px 1px 2px black" :style="{ display: oka ? 'block' : 'none' }">
    <br />
    <h1 style="text-decoration: underline">MIS CRUCIGRAMAS</h1>
    <br />
    <div v-if="diccionario === undefined">
      <h2>Primero debes compilar algun(os) diccionario(s)</h2>
    </div>

    <div v-else class="grid">
      <!--  -->
      <label>ANCHO:</label><input type="number" v-model.number="ancho" min="5" :max="anchoMaximo" @focusout="ancho = Math.min(Math.max(ancho, 5), anchoMaximo)" />
      <!--  -->
      <label>ALTO:</label><input type="number" v-model.number="alto" min="5" :max="altoMaximo" @focusout="alto = Math.min(Math.max(alto, 5), altoMaximo)" />
      <!--  -->
      <label>PASOS:</label><input min="1" type="number" v-model.number="iteraciones" /><label>PALABRAS:</label><input min="1" type="number" v-model.number="palabrasPorIteracion" />
      <!--  -->
      <label>SOLUCIONES:</label><input min="1" type="number" v-model.number="solucionesPorIteracion" />
      <!--  -->
      <label>SELECCIÓN:</label><input min="1" type="number" v-model.number="solucionesSeleccionadas" />
      <!--  -->
      <label for="bordes" style="cursor: pointer">BORDE:</label><input id="bordes" style="height: 1.6rem; cursor: pointer" type="checkbox" v-model="bordes" />
      <!--  -->
      <input placeholder="SEMILLA" v-model="semilla" style="grid-column: span 2; max-width: calc(100% - 3px) !important" />
    </div>
    <div v-if="diccionario !== undefined">
      <button v-if="proceso" @click="detener" style="width: calc(100% - 8px)">DETENER</button>
      <div style="text-align: left; display: block; border: 1px solid gray; padding: 4px 4px 0; margin: 8px; border-radius: 8px; text-shadow: none">
        <div style="height: 28px">
          <button v-if="matriz !== undefined" style="float: right; position: relative; top: -1px; font-size: 16px; padding: 1px 4px; opacity: 1" @click="compartir()">COMPARTIR</button>
          Hashtags:
        </div>
        <TagEditor :allNewValue="true" :newValue="newValue" color="black" :colors="['var(--color-select)', 'var(--color-gradient)']" startChars="#" ref="tags" :fixed="['#' + ancho + 'x' + alto]" :tagLoad="tags" :lowerCase="true" />
      </div>
      <select v-if="!proceso && diccionario !== undefined" @change="setTipo($event.target.value)" style="border: none:outline:none;padding:10px;border-radius:8px">
        <option value="1">NUEVO CRUCIGRAMA</option>
        <option value="2">15 CRUCIGRAMAS COMPLETOS</option>
        <option value="3">30 CRUCIGRAMAS COMPLETOS</option>
        <option value="4">60 CRUCIGRAMAS COMPLETOS</option>
      </select>
      <button v-if="!proceso && diccionario !== undefined" @click="generar" style="width: calc(100% - 8px); font-size: 28px; padding: 8px" :disabled="ok">
        <i style="font-size: 24px" class="ti-package"></i>
        GENERAR
      </button>

      <div v-if="matriz !== undefined">
        <br />
        <h1>CRUCIGRAMA GENERADO</h1>
      </div>

      <pre class="view-container" style="text-shadow: none; color: yellow" id="cruci"></pre>
    </div>
  </div>
</template>
<script>
import ConwordsGenerator from '../crucigramas/ConwordsGenerator';
import { doc, runTransaction, getFirestore, Timestamp, collection } from 'firebase/firestore';
import { mapState } from 'vuex';
import { getAuth } from '@firebase/auth';
import TagEditor from '../components/TagEditor.vue';
export default {
  name: 'Build',
  props: ['id'],
  components: {
    TagEditor,
  },
  data() {
    return {
      oka: false,
      tags: [],
      tipo: '1',
      semilla: '',
      diccionario: undefined,
      ancho: 8,
      alto: 8,
      anchoMaximo: 50,
      altoMaximo: 50,
      iteraciones: 8,
      solucionesPorIteracion: 12,
      solucionesSeleccionadas: 7,
      palabrasPorIteracion: 2,
      bordes: true,
      resp: '',
      matriz: undefined,
      proceso: false,
    };
  },
  computed: {
    ok() {
      return this.ancho === '' || this.alto === '' || this.iteraciones === '' || this.solucionesPorIteracion === '' || this.solucionesSeleccionadas === '' || this.palabrasPorIteracion === '';
    },
    ...mapState(['uid']),
  },
  async mounted() {
    let s = this.getLocal('buildParams');
    if (s !== null) {
      let o = JSON.parse(s);
      this.ancho = o.ancho;
      this.alto = o.alto;
      this.iteraciones = o.iteraciones;
      this.solucionesPorIteracion = o.solucionesPorIteracion;
      this.solucionesSeleccionadas = o.solucionesSeleccionadas;
      this.palabrasPorIteracion = o.palabrasPorIteracion;
      this.bordes = o.bordes;
      this.semilla = o.semilla;
    }
    s = this.getLocal('buildTags');
    if (s !== null) {
      const tags = JSON.parse(s)
        .filter((t) => t.match(/^#[0-9]+x[0-9]+$/) === null)
        .filter((t) => t.length > 1);
      this.tags = tags;
    }

    let db = this.db();
    if (db) {
      let transaction = db.transaction('compilacion', 'readonly');
      let diccs = transaction.objectStore('compilacion');
      let list = diccs.get('compilacion');
      transaction.oncomplete = () => {
        if (list.result !== undefined) {
          this.diccionario = list.result.compilacion;
          console.log('DICCIONARIO LOADED');
          this.oka = true;
        } else {
          this.oka = true;
        }
      };
    }
    this.setLoading(false);
  },
  methods: {
    setTipo(tipo) {
      this.tipo = tipo;
    },
    newValue(tags) {
      tags = tags.filter((t, idx) => idx > 0);
      //elimina tags repetidos y de dimensiones
      this.tags = tags;
      tags = tags.filter((t, idx) => tags.indexOf(t) === idx || t.match(/^#[0-9]+x[0-9]+$/) !== null);
      this.setLocal('buildTags', JSON.stringify(tags));
    },
    compartir: async function () {
      const desc = [...this.tags];
      desc.splice(0, 0, '#' + this.matriz[0].ancho + 'x' + this.matriz[0].alto);
      const db = getFirestore();
      const uid = getAuth().currentUser.uid;
      const now = Timestamp.now();
      const crucigramaData = {
        ancho: this.matriz[0].ancho,
        alto: this.matriz[0].alto,
        preguntas: this.matriz[0].preguntasData,
        uid: uid,
        fecha: now,
        desc: desc,
      };
      let newDoc = undefined;
      try {
        const tr = await runTransaction(db, async (transaction) => {
          const myCollectionRef = collection(db, 'crosswords');
          newDoc = doc(myCollectionRef);
          await transaction.set(newDoc, crucigramaData);
          const crucigramaSocial = {
            desc: desc,
            fecha: now,
            stars: 0,
            votos: 0,
            plays: 0,
            times: 0,
            timesCount: 0,
            uid: uid,
          };
          newDoc = doc(db, 'social', newDoc.id);
          await transaction.set(newDoc, crucigramaSocial);
        });
        if (newDoc) {
          if (this.tipo === '1') {
            this.playWord();
            this.notification('Crucigrama compartido!!');
          } else {
            this.playWord();
            this.notification('Crucigrama compartido!!');
          }
        }
      } catch (error) {}
    },
    detener: function () {
      const s = this.getLocal('audio');
      const audio = s ? parseInt(s) : 2;
      this.playClick(audio);

      this.proceso = false;
      this.matriz = undefined;
      document.getElementById('cruci').innerHTML = '';
    },
    generar: async function () {
      let tags = this.tags.join(' ').replace(/#/g, '');
      const s = this.getLocal('audio');
      const audio = s ? parseInt(s) : 2;
      this.playClick(audio);

      let count = 0;

      let storeParams = {
        ancho: this.ancho,
        alto: this.alto,
        iteraciones: this.iteraciones,
        solucionesPorIteracion: this.solucionesPorIteracion,
        solucionesSeleccionadas: this.solucionesSeleccionadas,
        palabrasPorIteracion: this.palabrasPorIteracion,
        bordes: this.bordes,
        semilla: this.semilla,
      };
      this.setLocal('buildParams', JSON.stringify(storeParams));

      //this.setLocal('buildTags', JSON.stringify(this.tags.filter((t) => t.match(/#\d+x\d+/) === null)));
      const generador = new ConwordsGenerator({
        compilacion: this.diccionario,
        ancho: this.ancho,
        alto: this.alto,
        palabrasEnBorde: this.bordes ? 0.9 : undefined,
        palabrasPorIteracion: this.palabrasPorIteracion,
        solucionesPorIteracion: this.solucionesPorIteracion,
        solucionesSeleccionadas: this.solucionesSeleccionadas,
      });
      let repetir = true;
      try {
        while (repetir) {
          this.proceso = true;
          this.matriz = undefined;
          const updateResp = async (resp) => {
            document.getElementById('cruci').innerHTML = resp;
            await this.delay(100);
          };

          let matriz = generador.generar(this.semilla !== '' ? this.semilla : undefined);

          for (let i = 0; i < this.iteraciones; i++) {
            if (this.proceso) {
              matriz = generador.iterar(matriz);
              await updateResp(`    SEMILLA ${generador.semilla} (${i + 1}/${this.iteraciones})\n\n` + generador.toString(matriz));
            } else {
              return;
            }
          }

          matriz = generador.seleccionarSoluciones(matriz, 1);

          matriz = generador.completar(matriz);

          await updateResp(`    SEMILLA ${generador.semilla}\n` + generador.toString(matriz, true));

          this.matriz = generador.ordenarPreguntas(matriz);
          const final = matriz[0];
          if (this.tipo === '1') {
            repetir = false;
            console.log(`${count}: ${final.preguntasData.map((p) => p.palabra).join(',')}`);
            this.compartir();
          } else {
            if (final.solas === 0) {
              const percent = Math.round((100 * final.llenado) / final.ancho / final.alto);

              if (this.tipo === '2') {
                generador.ignoreWords(final.preguntas);
                count++;
                console.log(`${count}: ${final.preguntasData.map((p) => p.palabra).join(',')}`);
                this.compartir();
                if (count >= 15) {
                  repetir = false;
                  const s = this.getLocal('audio');
                  const audio = s ? parseInt(s) : 2;
                  this.playComplete(audio);
                }
              } else if (this.tipo === '3') {
                generador.ignoreWords(final.preguntas);
                count++;
                console.log(`${count}: ${final.preguntasData.map((p) => p.palabra).join(',')}`);
                this.compartir();
                if (count >= 30) {
                  repetir = false;
                  const s = this.getLocal('audio');
                  const audio = s ? parseInt(s) : 2;
                  this.playComplete(audio);
                }
              } else if (this.tipo === '4') {
                generador.ignoreWords(final.preguntas);
                count++;
                console.log(`${count}: ${final.preguntasData.map((p) => p.palabra).join(',')}`);
                this.compartir();
                if (count >= 60) {
                  repetir = false;
                  const s = this.getLocal('audio');
                  const audio = s ? parseInt(s) : 2;
                  this.playComplete(audio);
                }
              }
            } else {
              console.error('Palabra solas: ', final.solas, 'porcentaje: ', final.llenado / final.ancho / final.alto);
            }
          }
        }
      } catch (error) {
        console.log(error);
        this.notification({ message: 'No se pudo completar el crucigrama, revise la consola', type: 'error' });
        return;
      }

      this.proceso = false;
    },
  },
};
</script>
<style>
.v-tag-input,
.v-tag-list {
  width: calc(100% - 40px);
  text-align: center;
  margin: 0 20px 12px;
  white-space: nowrap !important;
}

.tag {
  background-color: #3273dc;
  color: #fff;
  padding: 6px 12px 6px 8px;
  border-radius: 12px;
}
.delete.is-small {
  height: 14px;
  width: 14px;
}
.tag .delete {
  margin-left: 0.25em;
  margin-right: -0.5em;
}

.delete {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(10, 10, 10, 0.2);
  border: none;
  border-radius: 290486px;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  height: 20px;
  outline: 0;
  position: relative;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  vertical-align: top;
  width: 20px;
}

.tag.is-info {
  background-color: #3273dc;
  color: #fff;
}

.grid {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr 2fr 1fr 2fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
  padding: 8px;
}
.grid label {
  padding-top: 4px;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
}
.grid input {
  min-width: 54px;
  max-width: calc(100% - 4px);
  text-align: center;
}
@media (max-width: 720px) {
  .grid {
    grid-template-columns: 2fr 1fr 2fr 1fr 2fr 1fr;
  }
}
pre {
  font-family: 'Ubuntu Mono', monospace;
  text-align: left;
  font-size: 22x important;
}
.grid button {
  grid-column: span 2;
}
@media (max-width: 580px) {
  .grid {
    grid-template-columns: 2fr 1fr 2fr 1fr;
  }
}
@media (max-width: 366px) {
  .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
</style>
