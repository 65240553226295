<template>
  <div>
    <div class="tag-editor" ref="editor" @click.stop="add('', $event)">
      <span v-if="editable === true" tabindex="-1" @click.stop="add('')" style="cursor: pointer; width: 24px; text-align: center">{{ search ? '🔎︎' : '+' }}</span>
      <span v-for="(tag, idx) in tags" tabindex="-1" :key="idx" @click.stop="click($event)" :style="{ cursor: 'pointer !important', background: colors[Math.max(0, startChars.indexOf(tag.length > 0 ? tag.charAt(0) : startChars.charAt(0)))] }">
        <label @click.stop="tagClick($event, tag)" v-if="idx >= fixed.length" @keydown="keydown($event, idx)" autocorrect="off" autocapitalize="off" :style="{ cursor: !editable && clickMethod ? 'pointer' : 'text', outline: 'none', textTransform: upperCase ? 'uppercase' : lowerCase ? 'lowercase' : 'none' }" spellcheck="false" @keydown.enter="newValueInternal" @blur="focus($event, idx)" :contenteditable="editable === true" @input="change($event.target, idx)">{{ tag }}</label>
        <label tabindex="-1" @click.stop="tagClick($event, tag)" v-else :style="{ cursor: !editable && clickMethod ? 'pointer' : 'text', outline: 'none', textTransform: upperCase ? 'uppercase' : lowerCase ? 'lowercase' : 'none' }" :key="key"> {{ tag }} </label>
        <button v-if="idx >= fixed.length && editable === true" tabindex="-1" @click.stop="del(idx)">x</button>
      </span>
    </div>
    <!-- <pre>{{ tags }}</pre> -->
  </div>
</template>
<script>
let lastFixed = '';
export default {
  name: 'TagEditor',
  data() {
    return {
      tags: undefined,
      key: 0,
    };
  },
  props: {
    allNewValue: {
      type: Boolean,
      default: false,
    },
    newValue: {
      type: Function,
      default: (value) => {
        //console.log(value);
      },
    },
    color: {
      type: String,
      default: 'white',
    },
    clickMethod: {
      type: Function,
      default: undefined,
    },
    editable: { default: true },
    tagLoad: {
      type: Array,
      default: () => [],
    },
    maxTags: {
      type: Number,
      default: 6,
    },
    fixed: { type: Array, default: () => [] },
    search: { default: false },
    upperCase: { default: false },
    lowerCase: { default: false },
    validChars: { default: '01234567890QWERTYUIOPASDFGHJKLÑZXCVBNM_' },
    startChars: { default: '#@' },
    colors: { type: Array, default: () => ['blue', 'red'] },
  },
  watch: {
    fixed: {
      handler: function () {
        if (this.fixed.join() !== lastFixed) {
          this.handler();
          this.tags = this.tags.filter((tag) => tag.length > 1);
          lastFixed = this.fixed.join();
        }
      },
      immediate: true,
    },
    tagLoad: {
      handler: function () {
        this.handler();
      },
      deep: true,
    },
  },
  created() {
    //console.log('------------------------> created ' + this.tagLoad);
    if (this.tagLoad) {
      this.tags = [...this.fixed, ...this.tagLoad].map((s) => (this.upperCase ? s.toUpperCase() : this.lowerCase ? s.toLowerCase() : s));
    }
  },
  methods: {
    handler() {
      if (this.tagLoad) {
        this.tags = [...this.fixed, ...this.tagLoad].map((s) => (this.upperCase ? s.toUpperCase() : this.lowerCase ? s.toLowerCase() : s));
      } else this.tags = [...this.fixed].map((s) => (this.upperCase ? s.toUpperCase() : this.lowerCase ? s.toLowerCase() : s));
      //this.notification('handler ' + this.tags + ' ' + this.tagLoad + ' ' + this.key);

      this.key++;
    },
    isDescendant(parent, child) {
      var node = child.parentNode;
      while (node != null) {
        if (node == parent) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    },
    tagClick(evt, tag) {
      if (!this.editable && this.clickMethod) {
        this.clickMethod(tag);
        evt.stopPropagation();
        evt.preventDefault();
      }
    },
    keydown(event, idx) {
      if (event.key === 'Backspace' && event.target.innerText === '') {
        this.del(idx);
        event.preventDefault();
        event.target.blur();
      } else if (event.key === 'Enter') {
        event.preventDefault();
        event.target.blur();
      }
    },
    newValueInternal() {
      if (this.tags !== undefined) {
        //console.log('newValue ' + this.tags);
        this.newValue(this.tags);
      }
    },
    del(idx) {
      // console.log('------------------------> del');
      this.tags.splice(idx, 1);
      this.newValueInternal();
      for (let i = 0; i < this.tags.length; i++) {
        this.$refs.editor.children[i + 1].children[0].innerText = this.tags[i];
      }
      if (this.tags.length > 0) {
        setTimeout(() => {
          if (this.$refs.editor !== undefined) {
            const target = this.$refs.editor.children[idx].children[0];
            try {
              target.focus();
            } catch (error) {}

            try {
              const range = document.createRange();
              range.selectNodeContents(target);
              range.setStart(target.childNodes[0], this.tags[idx - 1].length);
              range.setEnd(target.childNodes[0], this.tags[idx - 1].length);
              const sel = window.getSelection();
              sel.removeAllRanges();
              sel.addRange(range);
            } catch (error) {}
          }
        }, 50);
      }
    },
    click(evt) {
      // console.log('------------------------> click');
      if (this.editable === true) {
        const el = evt.target;
        if (el.nodeName === 'SPAN') {
          el.childNodes[0].focus();
        }
        evt.preventDefault();
      }
    },

    add(txt, event) {
      let idx = this.tags.indexOf('');
      idx = idx === -1 ? this.tags.indexOf(this.startChars.charAt(0)) : idx;
      if (txt === '' && idx > -1) {
        this.$refs.editor.children[idx + 1].children[0].focus();
        return;
      }
      // console.log('------------------------> add');
      if (this.editable === true && this.tags.length < this.maxTags) {
        this.tags.push(txt);
        this.$nextTick(() => {
          const el = this.$refs.editor.lastChild.childNodes[0];
          el.focus();
          if (txt !== '') {
            this.change(el, this.tags.length - 1);
          }
          this.$forceUpdate();
        });
      } else if (this.editable === true) {
        this.notification('Son ' + this.maxTags + ' etiquetas como máximo');
      } else {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    focus(evt, idx) {
      const el = evt.target;
      if ((el.innerText.trim().length === 1 && this.startChars.indexOf(el.innerText.trim()) === 0) || el.innerText === '') {
        this.tags.splice(idx, 1);
        //current element focus
        const currentFocus = document.activeElement;
        //check if the current focus is inside the editor
        // this.notification({ type: 'error', message: 'internal focus' + this.isDescendant(this.$refs.editor, currentFocus) });
        //this.newValueInternal();
      }
    },
    change(target, idx) {
      // console.log('------------------------> change ', target.innerText);
      if (this.editable && idx >= this.fixed.length) {
        let txt = (this.upperCase ? target.innerText.toUpperCase() : this.lowerCase ? target.innerText.toLowerCase() : target.innerText).replace(/(\n|\r)*/gm, '');
        let caretStart = window.getSelection().getRangeAt(0).startOffset;
        if (this.startChars.indexOf(txt.charAt(0)) === -1) {
          caretStart++;
          txt = this.startChars.charAt(0) + txt;
        }
        txt = txt.replace(' ', ' ');

        const idxSpace = txt.indexOf(' ');

        let split = idxSpace === -1 ? [txt] : [txt.substring(0, idxSpace), txt.substring(idxSpace + 1)];

        let incompleteTag = split.length > 1 && split[1] === '' && this.startChars.indexOf(split[0]) > -1;
        if (incompleteTag) {
          split = split.filter((s) => s !== '');
        }

        txt = split[0];

        let finaltxt = '';
        for (let i = 0; i < txt.length; i++) {
          let key = txt.charAt(i).toUpperCase();
          if (this.validChars.toUpperCase().indexOf(key) > -1 || (i === 0 && this.startChars.toUpperCase().indexOf(key) > -1)) {
            finaltxt += txt.charAt(i);
          }
        }
        target.innerText = finaltxt;
        if (finaltxt.length > 0) {
          caretStart = caretStart + finaltxt.length - txt.length;
          if (caretStart > finaltxt.length) {
            caretStart = finaltxt.length;
          }
          if (caretStart >= 0) {
            const range = document.createRange();
            range.selectNodeContents(target);
            range.setStart(target.childNodes[0], caretStart);
            range.setEnd(target.childNodes[0], caretStart);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
          }
        }
        //console.log(JSON.stringify(split));
        //let incompleteTag=split.length > 1 && split[1] === '' && this.startChars.indexOf(split[0].charAt(0)) > -1;

        this.tags[idx] = finaltxt;

        this.$forceUpdate();

        for (let i = 1; i < split.length; i++) {
          this.add(split[i]);
        }
        if (this.allNewValue) {
          this.newValueInternal();
        }
      }
    },
  },
};
</script>
<style scoped>
.tag-editor {
  width: 100%;
  padding: 0px;
}

.tag-editor span {
  cursor: text;
  display: inline-block;
  background-color: black;

  padding: 2px 0px 0px;
  border-radius: 9px;
  margin: 0 5px 5px 0;
}

.tag-editor label {
  padding: 0 8px;
  color: black !important;
  text-shadow: 0 0 1px black;
}

.tag-editor span button,
.tag-editor button {
  width: 18px;
  height: 18px;
  line-height: 12px;
  border: none;
  top: -1px;
  border-radius: 50%;
  padding: 3px;
  margin: 0 2px 0;
  cursor: pointer;
}
</style>
